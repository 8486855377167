import { Multiline } from '@caarl_assurance/front-lib';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUndoAlt } from 'react-icons/fa';
import { SimpleFormArgs } from '../../../types/Forms';
import TimezoneWarning from '../../utils/TimezoneWarning';
import DateHead from './DateHead';
import LawyerContent from './LawyerContent';

const SignAndConfirm = ({
  goTo,
  prev,
  next,
  claimData,
  title,
  intro,
}: SimpleFormArgs) => {
  const { t } = useTranslation(['form', 'common']);
  const datetime = dayjs(claimData.date).tz().toDate();
  const { lawyerObj, speciality, specialitiesDefinitions } = claimData;

  const specLabel = useMemo(() => {
    const [level1, level2] = speciality.split('_');
    if (!level2) return specialitiesDefinitions[speciality].title;
    return specialitiesDefinitions[level1]?.children[speciality].title;
  }, []);
  return (
    <div className="container-centered">
      {title && (
        <div className="text-title">{t('main-title', { ns: 'common' })}</div>
      )}
      <TimezoneWarning label={t('timezone-warning', { ns: 'common' })} />

      {intro && <Multiline className="text-subtitle">{t(intro)}</Multiline>}
      <div className="bg-white rounded-lg my-5 border border-grey-200 mt-6">
        <div className="p-4 text-sm border-b border-b-grey-200 text-black leading-none w-full">
          <DateHead datetime={datetime} />
        </div>
        <div className="p-4">
          <LawyerContent
            firmName={`${lawyerObj.firstName} ${lawyerObj.lastName}`}
            description={lawyerObj.description}
            picture={lawyerObj.picture}
            info1={t(specLabel)}
          />
        </div>
        <div className="p-3 text-center">
          <button
            type="button"
            onClick={() => goTo(next, {}, true)}
            className="btn mx-auto w-full py-4 rounded-md "
          >
            {t('Confirmer le rendez-vous')}
          </button>
        </div>
      </div>
      <button
        type="button"
        onClick={() => {
          goTo(prev, {}, false);
        }}
        className="flex font-thin text-left items-center justify-center border-2 border-darkblue mx-auto w-full py-2 mt-12 text-darkblue hover:bg-white rounded-lg"
      >
        <FaUndoAlt className="h-4 w-4 mr-3" />
        <div>{t('Choisir une autre date ou un autre avocat')}</div>
      </button>
    </div>
  );
};

export default SignAndConfirm;

import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import { FaChevronRight } from 'react-icons/fa';

const ChoiceButton = ({ name, option, onChange }) => {
  const { input } = useFieldApi({
    name,
    type: 'radio',
    value: option.value,
  });
  const el = option;
  return (
    <button
      type="button"
      className=" bg-white shadow-none text-sm text-left border gap-3 border-grey-200 p-4 mb-6 w-full !rounded-lg flex items-center"
      onClick={() => onChange(input.value)}
    >
      <div className="flex-shrink-0">
        <img src={el.icon} alt={el.label} className="w-8" />
      </div>
      <div className="flex-grow">
        {el.label}
        {el.sub && (
          <span className="font-thin text-grey-500 block">{el.sub}</span>
        )}
      </div>
      <div className="flex-shrink-0">
        <FaChevronRight className="h-5 w-5" />
      </div>
    </button>
  );
};

export default ChoiceButton;

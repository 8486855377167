import { Multiline } from '@caarl_assurance/front-lib';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleFormArgs } from '../../../types/Forms';
import { specialitiesDef } from '../../../types/claimdata';
import UserContext from '../../auth/context';
import { UserData } from '../../auth/types';
import SimpleForm from '../SimpleForm';
import NoCredits from './NoCredits';

const ChooseSpeciality = ({
  formSchema,
  claimData,
  actionMapper,
  goTo,
  fileUploadPath,
  filter,
  validate,
  step,
  next,
  prev,
  autoSubmit,
  title,
  intro,
}: SimpleFormArgs) => {
  const { t } = useTranslation('form');
  const [base, setBase] = useState<specialitiesDef>(
    claimData.specialitiesDefinitions
  );
  const [render, setRender] = useState(false);
  // claims left
  const { user } = useContext(UserContext) as unknown as { user: UserData };
  const { rendezVousJuridique } = user.services as {
    rendezVousJuridique: { maxClaims: number; currentPeriodClaims: number };
  };
  const claimsLeft =
    rendezVousJuridique.maxClaims - rendezVousJuridique.currentPeriodClaims;

  useEffect(() => {
    if (!claimData.userId) {
      return;
    }
    if (claimData.appointment?.data?.date) {
      goTo('meeting_nextmeeting');
      return;
    }
    setRender(true);
  }, [claimData.userId, JSON.stringify(claimData.appointment)]);

  const formSchemaFilled = useMemo(() => {
    const nuFS = { ...formSchema };
    const currentBase =
      claimData.specialitiesDefinitions[claimData.speciality]?.children ||
      claimData.specialitiesDefinitions;
    const specsBrutes = Array.from(
      new Set<string>(
        claimData.specialities.concat(
          claimData.specialities.map((s: string) => s.split('_').at(0))
        )
      )
    );
    nuFS.fields[0].options = Object.entries(currentBase)
      .map(([key, value]) => {
        if (specsBrutes.indexOf(key) !== -1) {
          return {
            value: key,
            label: t(value.title),
            sub: t(value.subtitle),
            className: 'h-24 bg-white shadow-none border border-gray-200 !p-2',
            subClass: 'line-clamp-2 text-gray-400 !font-',
            labelClass: 'line-clamp-2 leading-tight',
            icon: value.icon,
          };
        }
        return null;
      })
      .filter((x) => x);

    return nuFS;
  }, [JSON.stringify(base), claimData.speciality, claimData.specialities]);

  const goToBypass = (dest, data) => {
    if (dest !== next) {
      return goTo(dest, data);
    }
    const { speciality } = data;
    if (claimData.specialitiesDefinitions[speciality]?.children) {
      setBase(claimData.specialitiesDefinitions[speciality]?.children);
      return null;
    }
    return goTo(dest, data);
  };

  if (!render) return null;

  return (
    <>
      <div className="container-centered !pb-0">
        <div className="flex justify-between items-center">
          {title && (
            <div className="text-title">
              {t('main-title', { ns: 'common' })}
            </div>
          )}
          <div className="text-center leading-3 text-xs bg-violet-100 text-violet-400 py-1 px-3 rounded-lg">
            <span className="font-thin text-[9px]">{t('Il vous reste')}</span>
            <br />
            {claimsLeft}&nbsp;{t(claimsLeft > 1 ? 'crédits' : 'crédit')}
          </div>
        </div>
        {intro && <Multiline className="text-subtitle">{t(intro)}</Multiline>}
      </div>
      {claimsLeft === 0 && <NoCredits />}
      {claimsLeft > 0 && (
        <SimpleForm
          {...{
            formSchema: formSchemaFilled,
            goTo: goToBypass,
            claimData,
            fileUploadPath,
            filter,
            validate,
            step,
            next,
            prev,
            autoSubmit,
            actionMapper,
            // title,
            // intro,
          }}
        />
      )}
    </>
  );
};

export default ChooseSpeciality;

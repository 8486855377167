import { Multiline } from '@caarl_assurance/front-lib';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronRight, FaUndoAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { UPDATE_CLAIM } from '../../../redux/reducers/claimReducer';
import { SimpleFormArgs } from '../../../types/Forms';
import TimezoneWarning from '../../utils/TimezoneWarning';
import DateHead from './DateHead';
import LawyerContent from './LawyerContent';

const BlockLawyer = ({
  onChoose,
  picture,
  firmName,
  description,
  speciality,
}) => (
  <button
    type="button"
    onClick={onChoose}
    aria-label="Lawyer"
    className="bg-white py-4 text-black my-6 relative -left-4 xs:left-0 xs:w-full w-screen flex gap-4 items-center"
  >
    <LawyerContent
      picture={picture}
      firmName={firmName}
      description={description}
      info1={speciality}
    />
    <FaChevronRight className="h-5 w-5 mr-3 text-gray-300" />
  </button>
);

const ChooseLawyer = ({
  goTo,
  next,
  prev,
  claimData,
  title,
  intro,
}: SimpleFormArgs) => {
  const { t } = useTranslation(['form', 'common']);
  const dispatch = useDispatch();
  const datetime = claimData.date;
  const specLabel = useMemo(() => {
    const [level1, level2] = claimData.speciality.split('_');
    if (!level2)
      return claimData.specialitiesDefinitions[claimData.speciality].title;
    return claimData.specialitiesDefinitions[level1]?.children[
      claimData.speciality
    ].title;
  }, []);

  const onChoose = (lawyerData) => {
    dispatch({
      type: UPDATE_CLAIM,
      data: { lawyer: lawyerData._id, lawyerObj: lawyerData },
    });
    goTo(next, { lawyer: lawyerData._id, lawyerObj: lawyerData });
  };

  return (
    <div className="container-centered">
      {title && (
        <div className="text-title">{t('main-title', { ns: 'common' })}</div>
      )}
      <TimezoneWarning label={t('timezone-warning', { ns: 'common' })} />

      {intro && <Multiline className="text-subtitle">{t(intro)}</Multiline>}
      <div className="p-3 border text-sm border-gray-200 px-4 rounded-lg bg-white text-black my-6">
        <DateHead datetime={datetime} />
        <div className="mt-3">{t('en visio conférence')}</div>
      </div>
      <div className="text-subtitle">
        {t(
          'Il y a plusieurs avocats disponibles sur ce créneau, avec qui voudriez-vous échanger ?'
        )}
      </div>
      {claimData.lawyers.map((lawyer) => (
        <BlockLawyer
          key={lawyer._id}
          onChoose={() => onChoose(lawyer)}
          firmName={`${lawyer.firstName} ${lawyer.lastName}`}
          description={lawyer.description}
          picture={lawyer.picture}
          speciality={t(specLabel)}
        />
      ))}

      <button
        type="button"
        onClick={() => {
          goTo(prev);
        }}
        className="flex text-left items-center justify-center border-2 border-darkblue mx-auto w-full py-2 mt-12 text-darkblue hover:bg-white rounded-lg"
      >
        <FaUndoAlt className="h-4 w-4 mr-3" />
        <div>{t('Choisir une autre date')}</div>
      </button>
    </div>
  );
};

export default ChooseLawyer;

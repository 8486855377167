import { Form, Multiline } from '@caarl_assurance/front-lib';
import { AutoSubmit } from '@caarl_assurance/front-lib/lib/components/forms/misc';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import { UPDATE_CLAIM } from '../../redux/reducers/claimReducer';
import { SimpleFormArgs } from '../../types/Forms';
import { useFileUpload } from '../../utils/hooks';
import { forEachObj } from '../../utils/misc';
import StepController from '../utils/StepController';

const Submit = ({
  autoSubmit = false,
  onPrev = null,
  canSubmit = false,
  touched = false,
  loading = false,
  nextLabel = 'Suivant',
}: {
  autoSubmit: boolean;
  onPrev?: ((e) => void) | null;
  canSubmit?: boolean;
  touched?: boolean;
  loading?: boolean;
  nextLabel?: string;
}) => (
  <>
    {autoSubmit && <AutoSubmit />}
    {!autoSubmit && (
      <StepController
        canSubmit={canSubmit}
        touched={touched}
        loading={loading}
        onNext="submit"
        nextLabel={nextLabel}
        onPrev={onPrev}
      />
    )}
  </>
);

const SimpleForm = ({
  claimData,
  formSchema,
  actionMapper,
  fileUploadPath,
  filter,
  validate,
  step,
  next,
  prev,
  autoSubmit,
  goTo,
  title,
  intro,
}: SimpleFormArgs) => {
  const { t } = useTranslation(['form', 'common']);
  const dispatch = useDispatch();
  const router = useRouter();
  const [error, setError] = useState(null);
  const { type: claimType } = router.query;
  const [fileHandler, isLoading] = useFileUpload(fileUploadPath || claimType);

  const handleSubmit = async (pData) => {
    try {
      setError(null);
      const filteredData = filter ? filter(pData) : { ...pData };
      const [data, errors] = await fileHandler(filteredData);

      if (Object.keys(errors).length > 0) {
        return errors;
      }

      if (validate) {
        const valErrors = await validate(data);
        if (valErrors) return forEachObj(valErrors, t);
      }
      dispatch({ type: UPDATE_CLAIM, data: { ...data } });
      return goTo(next, { ...data });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setError(
        t(e.message || 'Erreur veuillez ré-essayer ultérieurement', {
          ns: 'common',
        })
      );
      return e;
    }
  };

  const handleNav = (dest) => {
    if (dest) {
      return (e) => {
        setError(null);
        e.preventDefault();
        goTo(dest, null, false);
      };
    }
    return null;
  };

  return (
    <div className="container-centered">
      {title && (
        <div className="text-title">{t('main-title', { ns: 'common' })}</div>
      )}
      {intro && <Multiline className="text-subtitle">{t(intro)}</Multiline>}
      {error && <div className="text-red-500 my-4">{error}</div>}
      {isLoading && (
        <div className="text-grey-100 flex text-center items-center justify-center mt-32 flex-wrap absolute inset-0">
          <ClipLoader size={60} />
          <div className="w-full mt-4">
            {t("Veuillez patienter pendant l'envoi des fichiers...")}
          </div>
        </div>
      )}
      <Form
        initialValues={claimData}
        schema={formSchema}
        onSubmit={handleSubmit}
        actionMapper={actionMapper}
        step={step}
        className={`mt-4 ${isLoading ? 'hidden' : ''}`}
      >
        <Submit
          autoSubmit={autoSubmit}
          nextLabel={t('Suivant')}
          onPrev={handleNav(prev)}
        />
      </Form>
    </div>
  );
};
//         //

export default SimpleForm;

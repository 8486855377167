import { Multiline } from '@caarl_assurance/front-lib';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../../config/config';
import {
  REPLACE_CLAIM,
  UPDATE_CLAIM,
} from '../../../redux/reducers/claimReducer';
import { SimpleFormArgs } from '../../../types/Forms';
import { ClaimData } from '../../../types/claimdata';
import { useAxios, useIsFirstRender } from '../../../utils/hooks';
import UserContext from '../../auth/context';
import ConfirmModal from '../../utils/ConfirmModal';
import ShowOnTime from '../../utils/ShowOnTime';
import TimezoneWarning from '../../utils/TimezoneWarning';
import DateHead from './DateHead';
import LawyerContent from './LawyerContent';
import MeetingModal from './MeetingModal';

const MeetingList = ({ goTo, claimData, title, intro }: SimpleFormArgs) => {
  const { t } = useTranslation(['form', 'common']);
  const dispatch = useDispatch();
  const isFirstCall = useIsFirstRender();
  const { mutateUser } = useContext(UserContext);
  const axiosFetcher = useAxios();
  const [showGreets] = useState(!!claimData.date); // TODO remove state
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmPostpone, setShowConfirmPostpone] = useState(false);
  const { appointment } = claimData;
  const [roomURL, setRoomURL] = useState(null);

  useEffect(() => {
    if (!claimData.userId) {
      return;
    }
    if (!claimData.appointment?.data?.date) {
      goTo(claimData.speciality ? 'meeting_choosedate' : 'meeting_speciality');
      return;
    }
    dispatch({
      type: UPDATE_CLAIM,
      data: {
        ...claimData,
        date: undefined,
        lawyer: undefined,
        lawyerObj: undefined,
        clientComment: undefined,
      },
    });
  }, [JSON.stringify([claimData.userId, claimData.appointment])]);

  if (isFirstCall || !appointment?.data?.date) {
    return null;
  }

  const deleteAppointment = async (goto = 'meeting_speciality') => {
    try {
      await axiosFetcher.patch(
        `${API_URL}/claims/${appointment.id || appointment.number}/delete`
      );
      const {
        userId,
        specialitiesDefinitions,
        duration,
        specialities,
        prebook,
        insurer,
        generateCH,
      } = claimData;
      const keepData: ClaimData = {
        userId,
        specialitiesDefinitions,
        duration,
        specialities,
        appointment: undefined,
        prebook,
        insurer,
        generateCH,
      };
      if (goto === 'meeting_choosedate') {
        keepData.speciality = appointment.data.speciality;
        keepData.clientComment = appointment.data.clientComment;
        keepData.documents = appointment.data.documents.map((doc) => ({
          id: doc._id,
          inputValue: doc.name,
          inputFiles: [{ name: doc.name }],
        }));
      }

      dispatch({
        type: REPLACE_CLAIM,
        data: {
          ...keepData,
        },
      });
      mutateUser();
    } catch (error) {
      toast.error(
        error.response?.data?.errors?.date ||
          t('Il y a eu une erreur lors de la suppression du rendez-vous.')
      );
    }
  };

  const getMeeting = async () => {
    const { roomURL: rURL } = await axiosFetcher.patch(
      `${API_URL}/claims/${appointment.id || appointment.number}/meetingurl`
    );
    setRoomURL(rURL);
  };

  return (
    <div className="container-centered">
      {title && (
        <div className="text-title">{t('main-title', { ns: 'common' })}</div>
      )}
      <TimezoneWarning label={t('timezone-warning', { ns: 'common' })} />
      {roomURL && (
        <MeetingModal onClose={() => setRoomURL(null)} roomURL={roomURL} />
      )}
      {showGreets && (
        <div className="rounded-md bg-violet-100 flex gap-4 border-violet-400 text-sm border leading-tight relative p-4 my-6">
          {/* <button
            onClick={() => setShowGreets(false)}
            type="button"
            className="text-black absolute top-5 right-4 text-2xl leading-0"
          >
            &times;
          </button> */}
          <FaInfoCircle className="h-4 w-4 flex-shrink-0 text-violet-400" />
          <div>
            <div>{t('Votre rendez-vous de consultation est confirmé !')}</div>
            <div className="mt-2">
              {t(
                'Vous allez recevoir un mail de confirmation avec un lien pour demarrer la consultation.'
              )}
            </div>
          </div>
        </div>
      )}
      {intro && <Multiline className="text-subtitle">{t(intro)}</Multiline>}
      <div className="bg-white rounded-lg my-5 border border-grey-200 mt-6">
        <div className="p-4 text-sm border-b border-b-grey-200 text-black leading-none w-full">
          <DateHead datetime={appointment.data.date} />
        </div>
        <div className="p-4">
          <LawyerContent
            firmName={`${appointment.lawyer.firstName} ${appointment.lawyer.lastName}`}
            description={appointment.lawyer.description}
            picture={appointment.lawyer.picture}
            info1={t(appointment?.data?.specialityLabel.title)}
          />
        </div>
        <div className="p-4 mb-6">
          <ShowOnTime
            min={appointment.data.meetingBounds.min}
            max={appointment.data.meetingBounds.max}
            inProps={{}}
            outProps={{ disabled: true }}
          >
            <button
              type="button"
              onClick={getMeeting}
              className="btn font-thin text-center w-full py-3 mt-2 mb-4"
            >
              {t('Rejoindre la consultation')}
            </button>
          </ShowOnTime>
          <p className="text-grey-500 text-xs px-2 leading-snug text-center">
            {t(
              'Lorsque ce sera le moment, vous pourrez cliquer ci-dessous pour rejoindre la consultation.'
            )}
          </p>
        </div>
        <ShowOnTime
          min={appointment.data.meetingBounds.min}
          max={appointment.data.meetingBounds.max}
          inProps={{ className: 'hidden' }}
          outProps={{ disabled: true }}
        >
          <div className="p-4 items-center justify-between">
            <button
              onClick={() => setShowConfirmPostpone(true)}
              type="button"
              className="border border-black px-4 py-2 w-full rounded-md font-thin flex items-center justify-center hover:bg-gray-100 mb-4"
            >
              {/* <FaUndoAlt className="align-top inline-block h-3 w-3 mr-2" />{' '} */}
              {t('Déplacer le RDV')}
            </button>
            <button
              onClick={() => setShowConfirmDelete(true)}
              type="button"
              className="border border-black px-4 py-2 w-full rounded-md font-thin  flex items-center justify-center hover:bg-gray-100"
            >
              {/* <FaCalendarTimes className="align-top inline-block h-3 w-3 mr-2" />{' '} */}
              {t('Annuler le RDV')}
            </button>
          </div>
        </ShowOnTime>
        {showConfirmDelete && (
          <ConfirmModal
            onClose={() => setShowConfirmDelete(false)}
            onConfirm={() => deleteAppointment()}
            cancelLabel={t('Annuler')}
            confirmLabel={t('Confirmer')}
          >
            <div className="text-lg leading-tight mb-2">
              {t('Êtes-vous sûr(e) de vouloir supprimer ce rendez-vous ?')}
              <br />
            </div>
            <div className="text-violet-700 font-thin leading-tight">
              {dayjs().isBefore(dayjs(appointment.data.freeCancelLimit))
                ? t('il ne vous sera pas comptabilisé dans votre quota.')
                : t('Vu sa proximité, il sera déduit de votre quota.')}
            </div>
          </ConfirmModal>
        )}
        {showConfirmPostpone && (
          <ConfirmModal
            onClose={() => setShowConfirmPostpone(false)}
            onConfirm={() => deleteAppointment('meeting_choosedate')}
            cancelLabel={t('Annuler')}
            confirmLabel={t('Confirmer')}
          >
            <div className="text-lg font-thin leading-tight mb-2">
              <span className="font-bold block mb-2">
                {t('Êtes-vous sûr(e) de vouloir déplacer ce rdv ?')}
              </span>
              {t(
                "Il va être d'abord supprimé, et si vous n'en prenez pas d'autre, il sera considéré comme annulé."
              )}
            </div>

            {dayjs().isAfter(dayjs(appointment.data.freeCancelLimit)) && (
              <div className="text-violet-700 leading-tight">
                {t('En outre, vu sa proximité, il sera déduit de votre quota.')}
              </div>
            )}
          </ConfirmModal>
        )}
      </div>
    </div>
  );
};

export default MeetingList;

import cookieCutter from 'cookie-cutter';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../config/config';
import UserContext from '../auth/context';
import { UserData } from '../auth/types';

const Nav = (props) => {
  const { t } = useTranslation('common');
  const { logout } = useContext(UserContext);
  const [externalMenu, setExternalMenu] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const externalCookie = cookieCutter.get('caarl-menu');
    if (externalCookie) {
      setExternalMenu(JSON.parse(externalCookie));
    }
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  const handleSwitchSubscription = (e) => {
    e.preventDefault();
    window.location.href = `${API_URL}/auth/switcher?from=${window.location.origin}`;
  };

  if (!user) return null;

  const { hasOtherSubscriptions } = user as UserData;

  return (
    <nav className="flex justify-between items-center bg-transparent px-4 relative">
      <div className="text-left flex-shrink-0 flex-grow-0 w-20">
        {props.onBack && (
          <button
            type="button"
            onClick={props.onBack}
            className="whitespace-no-wrap font-normal text-caarlblue text-2xl relative inline-block align-middle"
          >
            <img
              src="/images/icons/chevron_left.svg"
              alt="go home"
              className="w-8"
            />
          </button>
        )}
      </div>
      <div className="text-right  flex-shrink-0 flex-grow-0 w-20">
        <button type="button" className="menu-toggle h-14">
          <img
            src="/images/icons/hamburger.svg"
            alt="menu opener"
            className="w-6 transform scale-75"
          />
        </button>
        <div className="menu-content bg-white shadow w-64 absolute right-0 font-normal z-10">
          <ul className="list-reset">
            {externalMenu.map((mElement) => (
              <li key={mElement.link}>
                <a
                  type="button"
                  href={mElement.link}
                  className="block p-4 border-white hover:pl-6 hover:border-black border-r-4 w-full text-right"
                >
                  {mElement.label}
                </a>
              </li>
            ))}
            {hasOtherSubscriptions && (
              <li>
                <button
                  type="button"
                  onClick={handleSwitchSubscription}
                  className="block p-4 border-white hover:pl-6 hover:border-black border-r-4 w-full text-right"
                >
                  {t('change-subscription')}
                </button>
              </li>
            )}
            <li>
              <button
                type="button"
                onClick={handleLogout}
                className="block p-4 border-white hover:pl-6 hover:border-black border-r-4 w-full text-right"
              >
                {t('disconnect')}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
